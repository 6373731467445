<template>
    <div class="display_links">
        <button class="btn02 bw2" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_1x2}"
                @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_1x2)"><i class="fa fa-futbol-o"></i> 1x2
        </button>
        <button class="btn02 bw2" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_SPECIAL}"
                @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_SPECIAL)"><i class="fa fa-star-half-o"></i>
            스페셜
        </button>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "SportsDisplayLinks",
        props: {
            displayPosition: {
                type: Number,
                default() {
                    return this.$store.state.displayPostion == null ? sportsConst.GAME_DISPLAYPOSITION_1x2 : this.$store.state.displayPostion;
                }
            },
        },
        data() {
            return {
                sportsConst,
                disp: this.displayPosition ? this.displayPosition : this.$store.state.displayPostion,
            }
        },
        methods: {
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                this.$store.commit(RECEIVE_DISPLAY_POSTION, -1);
                this.disp = disp
                this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
            }
        },
    }
</script>

<style scoped>
    .display_links {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 40%;
        flex-wrap: nowrap;
    }

    .display_links button {
        line-height: 30px;
    }
    @media screen and (max-width: 1024px) {
        .display_links{
            width: 100%!important;
            box-sizing: border-box;
            padding: 0 3px;
        }
    }
</style>