<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sports-links-comp :display-position="displayPosition"></sports-links-comp>
                <sports-display-links></sports-display-links>
                <transition-group class="sports" tag="div">
                    <div class="match_area" v-for="(game,index) in gameList" :key="index">
                        <div class="league">
                            <img alt="" :src="game.league.imageUrl" class="league_img">
                            <span class="league_name">{{game.league.leagueName}}</span>
                            <span class="st" style="float: right">{{game.startTime|datef('MM/DD HH:mm')}}</span>
                        </div>
                        <div class="match clearfix">
                            <div class="start_time">
                                {{game.startTime|datef('MM월DD일 HH:mm')}}
                            </div>
                            <div class="team_info">
                                <div class="detail">
                                    <div class="home bg_1x2"
                                         :class="{'active_sports':game.gameResult === sportsConst.GAME_RESULT_WIN_HOME}">
                                        <span class="home_name">{{game.homeTeamName}}</span>
                                        <span class="home_odds">
                                 <i v-if="game.kind === sportsConst.GAME_KIND_HANDICAP" class="fa fa-h-square h_icon"></i>
                                 <i v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER" class="fa fa-arrow-up o_icon"></i>
                                     {{game.homeTeamOdds}}
                                </span>
                                    </div>
                                    <!--무 3way-->
                                    <div class="draw bg_1x2" v-if="game.kind === sportsConst.GAME_KIND_1X2 && game.drawOdds > 0" :class="{'active_sports':game.gameResult === sportsConst.GAME_RESULT_DRAW}">
                                        <span class="draw_bedang">{{game.drawOdds}}</span>
                                    </div>
                                    <div class="draw ho" v-if="game.kind === sportsConst.GAME_KIND_1X2 && game.drawOdds <= 0">
                                        <span>vs</span>
                                    </div>
                                    <!--핸디캡 오버언더-->
                                    <div v-if="game.kind === sportsConst.GAME_KIND_HANDICAP" class="draw ho">{{game.handicapVal}}
                                    </div>
                                    <div v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER" class="draw ho">{{game.overunderVal}}
                                    </div>

                                    <div class="away bg_1x2" :class="{'active_sports':game.gameResult === sportsConst.GAME_RESULT_WIN_AWAY}">
                                <span class="away_name">
                                  {{game.awayTeamName}}
                                </span>
                                        <span class="away_odds">
                                    {{game.awayTeamOdds}}
                                    <i v-if="game.kind === sportsConst.GAME_KIND_HANDICAP" class="fa fa-h-square h_icon"></i>
                                    <i v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER" class="fa fa-arrow-down o_icon"></i>
                                </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more">
                                        <span class="text-orange" v-if="game.gameResult !== sportsConst.GAME_RESULT_CANCELED && game.gameResult !== sportsConst.GAME_RESULT_SPECIAL">
                                          {{game.homeTeamScore}}:{{game.awayTeamScore}}
                                        </span>
                                       <span class="text-dark" v-if="game.gameResult === sportsConst.GAME_RESULT_CANCELED">
                                          취소
                                        </span>
                                          <span class="text-orange" v-if="game.gameResult === sportsConst.GAME_RESULT_SPECIAL">
                                          적특
                                        </span>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {getSportsGameEnd} from "../../network/sportsRequest";
    import RightBarComp from "../../components/RightBarComp";
    import MobileSportsEndGamesFootMenuComp from "../../components/mobile/MobileSportsEndGamesMenuComp";
    import Pagination from "../../components/pagenation/Pagination";
    import SubTitle from "../../components/SubTitle";
    import {fix2, kindText} from "../../common/SportsUtils";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLinksComp from "../../components/links/SportsLinksComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {RECEIVE_CHECKED_GAME_TYPE, RECEIVE_CHECKED_LEAUGE_DELALL} from "../../store/mutation-types";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import GameResultLinks from "../../components/GameResultLinks";
    import SportsDisplayLinks from "../../components/links/SportsDisplayLinks";

    export default {
        name: "SportsGameResult",
        components: {
            SportsDisplayLinks,
            GameResultLinks,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp,
            TopbarComp,
            SportsLinksComp,
            LeftBarComp, SubTitle, Pagination, MobileSportsEndGamesFootMenuComp, RightBarComp
        },
        data() {
            return {
                displayPosition: this.$store.state.displayPostion,
                pageNum: 1,
                pageSize: 20,
                total: 1,
                orderBy: 'g.start_time DESC',
                search: {},
                gameList: [],
                sportsConst
            }
        },
        methods: {
            getGames() {
                this.search.displayPosition = this.displayPosition
                getSportsGameEnd(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {

                    if (res.data.success) {
                        this.total = res.data.total
                        this.gameList = res.data.data
                        this.gameList.forEach(g => {
                            //배당 소수점 두자리 설정 1.8 => 1.80
                            fix2(g)
                            //승무패,핸디오언 텍스트 설정
                            kindText(g)
                        })
                    }
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.getGames()
            }
        },
        created() {
            this.getGames();
        },
        watch: {
            "$store.state.displayPostion": {
                deep: true,
                handler: function (newVal) {
                    this.displayPosition = newVal
                    this.pageNum = 1
                    this.getGames();
                }
            },
        }


    }
</script>

<style scoped>

</style>