<template>
    <div class="button_group" style="margin-top: 10px;">
        <router-link class="btn02 bw2" tag="a"  :to="{path:'/sports_result'}">스포츠 결과</router-link>
        <router-link class="btn02 bw2" tag="a"  :to="{path:'/leisure_result'}">미니게임 결과</router-link>
    </div>
</template>

<script>
    export default {
        name: "GameResultLinks"
    }
</script>

<style scoped>

</style>